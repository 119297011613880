.center-me {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;
}

.form{
    display: flex;
    flex-direction: column;
}

.insideForm{
    padding: 8px;
}

.inputField{
    width: 400px;
}

.leftDiv{
    background-color: #FFFFFF;
    color: '#7002BC';
    background-image: url("https://www.hubita.co.uk/assets/front/images/transparent-bg.png");
    background-size: 100vw 100vh;
    background-repeat: no-repeat;
}

.rightDiv{
    background-color: #F2F5FA;    
}
