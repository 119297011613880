.form {
    display: flex;
    flex-direction: column;
    width: 30vw;
    padding: 25px;
}

.formHeader {
    padding: 10px 0px 0px 0px;
}

.formButton {
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin: 25px 0px 0px 0px;
}
